import React from "react";

import "./Plug.css";
import logo from "../DOTS.svg";

import Button from "../components/Button";

function Plug() {
 return (
  <div class="container">
   <div class="title">
    Revolutionizing the untouched industry with passion, science and technology
   </div>
   <div class="mail">
    <Button text="info@dots.inc" url="mailto:info@dots.inc" />
   </div>
   <div class="logo">
    <img src={logo} alt="DOTS logo" />
   </div>
  </div>
 );
}

export default Plug;
