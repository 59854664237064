import React from "react";

export default function Button(props) {
 return (
  <a href={props.url}>
   <div
    style={{
     minHeight: "var(--bt_min)",
     minWidth: "var(--bt_min)",
     // backgroundColor: "red",
     display: "flex",
     // direction: "row",
     // width: "100%",
     justifyContent: "center",
     alignItems: "center",
    }}
   >
    <button>{props.text}</button>
   </div>
  </a>
 );
}