import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Plug from "./pages/Plug";
import "./index.css";

function App() {
 useEffect(() => {
  // функция для определения устройства
  function getMobileOperatingSystem() {
   var userAgent = navigator.userAgent || navigator.vendor || window.opera;

   // определение iOS
   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
   }

   // определение Android
   if (/android/i.test(userAgent)) {
    return "Android";
   }

   return "unknown";
  }

  // слушанье высоты доступного окна браузера (чтобы navbar на ios не закрывал контент)
  function setAppHeight() {
   document.documentElement.style.setProperty(
    "--app_height",
    `${window.innerHeight}px`
   );
  }

  // устанавливаем высоту при загрузке страницы
  setAppHeight();
  // Обновляем высоту при изменении размера окна
  window.addEventListener("resize", setAppHeight);

  // определение ОС и установка соответствующего класса
  const os = getMobileOperatingSystem();
  if (os === "iOS") {
   document.body.classList.add("ios");
  } else if (os === "Android") {
   document.body.classList.add("android");
  }

  return () => {
   window.removeEventListener("resize", setAppHeight); // очищаем обработчик при размонтировании компонента
  };
 }, []);

 return (
  <Router>
   <Routes>
    <Route path="/" element={<Plug />} />
    <Route index element={<Plug />} />
    <Route path="*" element={<Plug />} />
   </Routes>
  </Router>
 );
}

export default App;
